<template>
    <div class="login d-flex justify-center align-center">
        <div class="login__containerForm">
            <div class="login__logo home__title d-flex justify-center align-center">
                <img height="66px" src="/imgs/logo_audycer.svg" />
                <h1 class="text-login ml-2">DEKATOO R&T ASOCIADOS SRL.</h1>
            </div>
            <v-form
                v-model="valid"
                v-on:submit.prevent="ac_sendFormUser()"
                lazy-validation
                ref="formlogin"
            >
                <MTextFieldComp
                    class="mt-5 mb-2"
                    v-model="datesUser.username"
                    :clearable="true"
                    label="Username"
                    :dense="true"
                    :counter="20"
                    @click="ac_sendFormUser()"
                    :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                        v => (v+'').length<=20 || 'El username no debe exeder los 20 dígitos',
                        v => (v+'').length>=6 || 'El username debe ser igual o mayor a 6 dígitos']"
                />
                <MTextFieldComp
                    v-model="datesUser.password"
                    :clearable="true"
                    label="Contraseña"
                    :dense="true"
                    :type="showPassword ? 'text' : 'password'"
                    :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @clicksee="showPassword = !showPassword"
                    @click="ac_sendFormUser()"
                    :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido']"
                />
            </v-form>
            <div class="text-center">
                <MBtnNormalComp
                    color="primary"
                    @click="ac_sendFormUser()"
                    :loading="loadingBtn"
                >INGRESAR</MBtnNormalComp>
            </div>
        </div>
        <OSmsErrorComp
            @click="dialogError=false"
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
        />
    </div>
</template>
<script>
import { MTextFieldComp, MBtnNormalComp } from '../../components/molecules'
import { OSmsErrorComp } from '../../components/organisms'
import { rulesMixin, responseServer } from '../../mixins'
import { User } from '../../models'
import { mapMutations } from 'vuex'
import md5 from 'js-md5'
export default {
    mixins: [rulesMixin, responseServer],
    components: {
        MTextFieldComp,
        MBtnNormalComp,
        OSmsErrorComp
    },
    data: () => ({
        dialogError: false,
        smsError: [],
        actionError: false,
        datesUser: new User(),
        showPassword: false,
        valid: true,
        loadingBtn: false
    }),
    methods: {
        ...mapMutations('userStore', ['saveHeader']),
        rulesDates() {
            if (!this.$refs.formlogin.validate()) { return false }
            return true
        },
        processError(error) {
            if (!this.m_response_autorization(error)) {
                this.smsError = ['El username o la contraseña están incorrectos, porfavor revise sus datos']
                this.actionError = true
            } else {
                this.smsError = ['Revise su conección de internet y refresque la página porfavor']
                this.actionError = false
            }
            this.dialogError = true
        },
        ac_sendFormUser() {
            if (this.rulesDates()) {
                this.loadingBtn = true
                var parameters = {
                    'username': this.datesUser.username,
                    'password': md5(this.datesUser.password)
                }
                User.login(parameters).then(response => {
                    localStorage.setItem('agapeconsultores', JSON.stringify(response))
                    var header = {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + response.token,
                    }
                    this.saveHeader(header)
                    this.loadingBtn = false
                    this.$router.replace('/')
                }).catch((error) => {
                    this.loadingBtn = false
                    this.processError(error)
                })
            } else {
                this.smsError = ['Corrija los datos marcados con rojo y vuelva a intentarlo porfavor']
                this.actionError = true
                this.dialogError = true
            }
        }
    },
    mounted() {
        if (JSON.parse(localStorage.getItem('agapeconsultores'))) {
            this.$router.replace('/')
        }
    }
}
</script>
<style scoped>
    .login{
        width: 100%;
        height: 100%;
        padding: 15px;
        background: rgb(255, 255, 255);
    }
    .login__containerForm{
        width: 380px;
        background: rgb(255, 255, 255);
        padding: 15px;
        border-radius: 10px;
        border: 1px solid rgb(211, 211, 211);
        /*box-shadow: 0px 0px 10px rgb(30, 31, 32);*/
    }
    .login__logo{
        width: 100%;
        height: 80px;
        background: linear-gradient(221deg, #021227ad 0%, #062b41 100%);
        border-radius: 5px;
    }
</style>